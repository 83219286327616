  
 const faviconUrl = process.env.PUBLIC_URL + '/favicon.ico' 

 const APP_COLOR = "#2aee62";

 document.documentElement.style.setProperty('--app-color', APP_COLOR);

 
 const images = {
  about_img :"https://i.imgur.com/WbQnbas.png",
 }

 const videos = {
  HOME_YOUTUBE_VIDEO_ID:'6N412Hwkews',
 }

 const Email_keys = {
  service_key: 'service_9wtkuxi',
  template_key:'template_m6jom8i',
  public_key:'hdt0G9szOMDUujmnS',
 }

 const CONTACT_INFO = {
  hr_solution_email: "solutions@canaffor.com",  
  consulting_email: "info@canaffor.com",
  cv_email: "cvs@canaffor.com",
  buisness_enquiry_email: "info@canaffor.com",
  hr_advisiory_phone:"+91 7718889909",
  recruitment_supportPhone: "+91 7506661939",
  address_location_key: "https://www.google.com/maps/search/?api=1&query=19.1197,72.8462",
};

 const SOCIAL_MEDIA_LINKS = {
  facebook: "https://www.facebook.com/CanafforCorporation",
  twitter: "https://x.com/canaffo",
  linkedin: "https://www.linkedin.com/company/canaffor-corporation/posts/?feedView=all",
  instagram: "https://www.instagram.com/canafforcorporation",
  youtube: "https://www.youtube.com/watch?v=NkHHN46UfVo",
};

 const COMPANY_DETAILS = {
  name: "Canaffor Corporation | For smarter HR Solutions",
  address: "Andheri West, Mumbai, India",
  website: "https://www.canaffor.com/",
  Corporate_Identity_Number:"",
};

const Job_apply ={
  web_portal:'https://canaffor-jobportal.web.app/',
};

 export {APP_COLOR,images,videos,Email_keys,CONTACT_INFO, SOCIAL_MEDIA_LINKS, COMPANY_DETAILS,Job_apply, faviconUrl}