import React from 'react';
import {  Route, Routes } from 'react-router-dom'; 
import './index.css';
import Navbar from './Component/Navbar/Navbar';
import Home from './Component/Home/Home'

import Footer from './Component/Footer/Footer';
import ContactUs from './Component/Contact/Contact'
import Header from './Component/Logo Header/Header';
import Recuritment from './Component/Recruitment/Recruitment';
import Hr_consulting from './Component/HR_CONSULTING/Hr_consulting';


const App = () => {
  return (
    <>
    
    <Navbar />
    <Routes>

    <Route path="/" element={<Home />} />
    <Route path="/contact" element={<ContactUs />}  />
    <Route path="/recruitment" element={<Recuritment />}  />
    <Route path="/hr_consulting'" element={<Hr_consulting />}  />

    </Routes>
    <Footer/>
    
    </>
    
   
  
   
    
  );
};

export default App;
