import React from "react";
import { Link } from 'react-router-dom';
import './AboutUs.css'; 
import {CONTACT_INFO, images} from '../Constant'

function AboutUs() {
  return (
    <section className="about-section flex flex-col-reverse sm:flex-row items-center max-w-screen-xl mx-auto p-5 sm:p-10">
      <div className="about-content w-full sm:w-1/2 p-5">
        <div className="text">
          <span className="about-heading">About Us</span>
          <h2  className="about-title">
            About <span  className="highlighted-text">Canaffor Corporation</span>
          </h2>

          <h5 className="subheading font-semibold"> 
            Leading HR Solutions Provider & <span className="font-semibold">Advisory</span>
          </h5>
          <p className="about-paragraph"> 
            Canaffor Corporation, headquartered in Mumbai, India, is a leading
            end-to-end HR solutions provider focused on Executive Search,
            Recruitment, Training, Certifications, and HR outsourcing/Advisory.
          </p>
          <p className="about-paragraph"> 
            Canaffor is the fastest-growing HR solutions company in India and
            overseas markets. We are an established human resource partner to
            many of the leading Indian corporates and MNCs across India and
            overseas locations.
          </p>
          <p className="about-paragraph"> 
            We have a dedicated team of experts in each of the verticals:
          </p>
          <ul className="about-list"> 
            <li>Recruitment/Training & Certification</li>
            <li>HR Advisory/Outsourcing & Legal Compliances</li>
          </ul>
          <div className="data">
            <Link to= "/contact" className="button contact-button">
              <div className="dots_border"></div>
              <span className="text_button">Contact us</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="image-container w-full sm:w-1/2 p-5">
        <img 
          src= {images.about_img} 
          alt="About Us" 
          className="about-image"
        />
      </div>
    </section>
  );
}

export default AboutUs;
