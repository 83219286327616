import React, { useState } from "react";

const Recuritment = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is Canaffor’s experience in recruitment?",
      answer: "With over 20 years of expertise, Canaffor has refined its recruitment skills to support hiring needs across various sectors and regions effectively.",
    },
    {
      question: "How satisfied are Canaffor’s clients?",
      answer: "All of Canaffor’s clients have chosen to continue their relationship with us, reflecting the high trust and satisfaction they have in our results and service.",
    },
    {
      question: "What makes Canaffor’s recruitment process unique?",
      answer: "Canaffor’s recruitment approach includes quality sourcing, quick turnaround times, and high retention rates for placements. We focus on finding the perfect business and cultural fit for each role.",
    },
    {
      question: "Does Canaffor specialize in any particular industries?",
      answer: "Canaffor has expertise across all verticals, enabling us to meet the unique hiring needs of a wide range of industries.",
    },
    {
      question: "How does Canaffor ensure a good fit for each role?",
      answer: "Our process includes headhunting, thorough candidate assessments, and a 360-degree evaluation to find the right fit for both the position and company culture.",
    },
    {
      question: "What candidate assessments does Canaffor use?",
      answer: "Canaffor uses a blend of psychometric tests, technical assessments, and performance-based interviews to thoroughly evaluate skills and competencies.",
    },
    {
      question: "How does Canaffor evaluate candidate potential?",
      answer: "We assess both immediate competencies and long-term potential through structured interviews and runway validation, ensuring candidates can thrive within our clients’ organizations.",
    },
    {
      question: "How quickly can Canaffor fill positions?",
      answer: "Canaffor is known for its quick turnaround time, efficiently managing the recruitment process to minimize hiring time and meet clients' needs promptly.",
    },
  ];

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="max-w-3xl mx-auto my-8 p-6">
      <h2 className="text-3xl font-bold text-center mb-6">Frequently Asked Questions</h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-300 rounded-lg overflow-hidden"
          >
            <button
              onClick={() => toggleFaq(index)}
              className="flex justify-between items-center w-full p-4 text-left text-lg font-semibold bg-gray-100 hover:bg-gray-200 focus:outline-none"
            >
              <span>{faq.question}</span>
              <span>{activeIndex === index ? "–" : "+"}</span>
            </button>
            {activeIndex === index && (
              <div className="p-4 text-gray-600 bg-white">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recuritment;
