import React from 'react'

const Hr_consulting = () => {
  return (
    <div id='hr-consulting-tab'>
      <h1 className='bg-red-700'>working</h1>
    </div>
  )
}

export default Hr_consulting
