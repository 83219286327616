// Navbar.jsx
import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { Job_apply } from '../Constant';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('Home');
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsOpen(false);
  };

  useEffect(() => {
    const sidebar = document.querySelector('.sidebar');
    if (sidebar) {
      if (isOpen) {
        sidebar.classList.add('active');
      } else {
        sidebar.classList.remove('active');
      }
    }
  }, [isOpen]);

  const handleApplyNowClick = () => {
    window.location.href = `${Job_apply.web_portal}`;
  };

  return (
    <nav className={`navbar ${scrolled ? 'shadow-xl' : ''} sticky top-0 z-30 w-full px-4 md:px-10`}>
      {/* Logo and Slogan (from Header component) */}
      <div className={`flex items-center transition-opacity duration-300 `}>
        <img src={`${process.env.PUBLIC_URL}/images/logo.jpeg`} alt="Logo" className="pt-3 h-20 md:h-32 lg:h-30" />
        <img src={`${process.env.PUBLIC_URL}/images/slogan.png`} alt="Slogan" className="h-16 md:h-24 lg:h-20 ml-4" />
      </div>

      <div className="nav-links">
        {['Home', 'HR Consulting', 'Recruitment', 'Certification', 'Contact US'].map((link) => (
          <Link
            key={link}
            to={link === 'Contact US' ? '/contact' : link === 'Home' ? '/' : link === 'Recruitment'? '/recruitment': link ==='HR Consulting' ? '/hr_consulting': '#'}
            className={`nav-link  ${activeLink === link ? 'active' : ''}`}
            onClick={() => handleLinkClick(link)}
          >
            {link}
          </Link>
        ))}
      </div>

      <button className="button" onClick={handleApplyNowClick}>
        Apply for Jobs
        <svg className="icon" viewBox="0 0 24 24" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>

      <div className="hamburger" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? '✖' : '☰'}
      </div>

      {isOpen && (
        <div className="sidebar">
          <div className="sidebar-links">
            {['Home', 'HR Consulting', 'Recruitment', 'Certification', 'APPLY NOW'].map((link) => (
              <a
                key={link}
                href="#"
                className={`nav-link ${activeLink === link ? 'active' : ''}`}
                onClick={() => handleLinkClick(link)}
              >
                {link}
              </a>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
