import React, { useState, useEffect } from "react";


const testimonials = [
    {
        id: 1,
        text: "There are many variations of passages of Lorem Ipsum available...",
        name: "Gautam Menon",
        position: "CTO",
        location: "Mumbai, India",
        companyLogo: "",
        profilePic: "  ",
    },
    {
        id: 2,
        text: "They organized their work and internal management was outstanding.",
        name: "Harshad Mehta",
        position: "Project Manager, Buildwave",
        location: "Mumbai, India",
        companyLogo: "",
        profilePic: "",
    },
    {
        id: 3,
        text: "Working with them was a great experience.",
        name: "Mukesh Ambani",
        position: "Lead Engineering, InHive Space",
        location: "Mumbai, India",
        companyLogo: "",
        profilePic: "",
    },
    {
        id: 4,
        text: "A fantastic team to work with!",
        name: "Ratan Tata",
        position: "Investor, Tata Group",
        location: "Mumbai, India",
        companyLogo: "",
        profilePic: "",
    },
    {
        id: 5,
        text: "Their solutions are innovative and reliable.",
        name: "Sundar Pichai",
        position: "CEO, Google",
        location: "California, USA",
        companyLogo: "",
        profilePic: "",
    },
];

const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(1);

    useEffect(() => {
        const updateItemsToShow = () => {
            if (window.innerWidth >= 1024) {
                setItemsToShow(3);
            } else if (window.innerWidth >= 768) {
                setItemsToShow(2);
            } else {
                setItemsToShow(1);
            }
        };

        updateItemsToShow();
        window.addEventListener("resize", updateItemsToShow);

        return () => window.removeEventListener("resize", updateItemsToShow);
    }, []);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - itemsToShow : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + itemsToShow >= testimonials.length ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="bg-white py-8">
            <div className="container mx-auto px-6 text-center">
                <h2 className="text-3xl font-bold text-gray-800 mb-8">What Our Clients Say</h2>
                <div className="flex items-center justify-between gap-4 w-full mx-auto ">
                    <button
                        onClick={handlePrev}
                        className="bg-black text-white p-2 md:p-3 rounded-full hover:bg-gray-700"
                        style={{ width: '3rem', height: '3rem' }}
                        aria-label="Previous"
                    >
                        &#8592;
                    </button>

                    <div className="flex overflow-hidden w-full justify-around gap-4 px-4 py-5">
                        {testimonials.slice(currentIndex, currentIndex + itemsToShow).map((testimonial) => (
                            <div key={testimonial.id} className="bg-slate-100 shadow-xl rounded-lg p-8 w-full max-w-xs flex flex-col justify-center  text-left">
                                <div className="flex items-center mb-6 justify-center">
                                    <img
                                        src={testimonial.profilePic || ""}
                                        alt={testimonial.name}
                                        className="w-12 h-12 rounded-full mr-4"
                                    />
                                    <img src={testimonial.companyLogo || ""} alt="Company logo" className="h-10" />
                                </div>
                                <div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/inverted_comma.png`}
                                        className="h-5 pb-2"
                                    >
                                    </img>
                                </div>
                                <p className="text-black font-semibold mb-8 text-2xl text-left">{testimonial.text}"</p>
                                <div className="text-left ml-0">
                                    <p className="font-semibold text-gray-800 text-lg text-left">{testimonial.name}</p>
                                    <p className="text-gray-500 text-left">{testimonial.position}</p>
                                    <p className="text-gray-500 text-left">{testimonial.location}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <button
                        onClick={handleNext}
                        className="bg-black text-white p-2 md:p-3 rounded-full hover:bg-gray-700"
                        style={{ width: '3rem', height: '3rem' }}
                        aria-label="Next"
                    >
                        &#8594;
                    </button>
                </div>
            </div>
        </div>


    );
};

export default Testimonials;

