import React from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_COLOR, Job_apply } from '../Constant';

import './Content.css'

const services = [
  {
    title: 'Training and Development',
    description: 'We offer a comprehensive range of solutions, including LMS, skill gap analysis, bridging skill gaps, psychometric testing, TNI, assessment centres, and training delivery. Our services also cover content planning, re-training, certifications, and specialized programs like Train the Trainer, LDP, and MDP, ensuring employee growth and development.',
    buttonLabel: 'Learn More',
    buttonLabe2: 'Request a Consultation',
    imgSrc: '/images/tranning.jpeg',
  },
  {
    title: 'Recruitment and Outsourcing',
    description: 'Our recruitment and outsourcing services focus on quality sourcing, screening, headhunting, and swift placements. We provide job & market research, benchmarking, shortlisting, and interview line-ups, ensuring long-term hires that fit your company culture. We also offer job portal solutions and conduct 360-degree candidate assessments to guarantee business and cultural alignment.',
    buttonLabel: 'Explore Our Portal',
    buttonLabe2: 'See FAQs',
    imgSrc: '/images/recruitment.jpeg',
  },
  {
    title: 'HR Consulting',
    description: 'We provide climate surveys, HR audits, and organizational capability assessments. Our services include aligning vision, mission, and core values, setting value propositions, KRAs & KPIs, implementing OD interventions, and designing reward & recognition programs. We also support sales incentives and ensure compliance with legal and regulatory requirements',
    buttonLabel: 'Discover HR Solutions',
    buttonLabe2: 'Book a Consultation',
    imgSrc: '/images/hr_consulting.jpeg',
  },
];

const ServiceCards = () => {
  const navigate = useNavigate();

  const handleButtonClick = (buttonLabel) => {
    switch (buttonLabel) {
      case 'Learn More':
        document.getElementById('video-section')?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'Explore Our Portal':
        window.open(Job_apply.web_portal, '_blank');
        break;
      case 'Discover HR Solutions':
        navigate('/hr-consulting');
        break;
      default:
        console.warn('No action defined for this button.');
    }
  };

  const handleButtonClick2 = (buttonLabe2) => {
    switch (buttonLabe2) {
      case 'Request a Consultation':
        navigate('/contact');
        break;
      case 'See FAQs':
        navigate('/recruitment');
        break;
      case 'Book a Consultation':
        navigate('/contact');
        break;
      default:
        console.warn('No action defined for this button.');
    }
  };
  {/*className="content-details relative flex flex-col items-center bg-white p-6 rounded-lg shadow-md w-full max-w-sm min-h-[400px] md:min-h-[500px] border-4 
            border-gray-300 hover:border-orange-400 transition-all duration-300 transform hover:scale-105 hover:shadow-lg" */}

  return (
    <div className="flex flex-wrap justify-center items-stretch gap-8 px-4 py-10 bg-white">
      {services.map((service, index) => (
        <div
          key={index}
          className="content_details relative flex flex-col items-center bg-white p-6 rounded-lg shadow-md w-full max-w-sm min-h-[400px] md:min-h-[500px] border-4 
            border-gray-300 hover:border-green-400 transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
        >
          {/* Floating Image */}
          <div className="absolute -top-10">
            <img 
              src={service.imgSrc} 
              alt={service.title} 
              
              className="h-28 w-28 object-contain rounded-lg shadow-md hover:scale-105 transition-transform duration-300 ease-in-out content-image" 
            />
          </div>

          <h3 className="text-xl font-semibold mt-16 mb-2 text-center">{service.title}</h3>
          <p className="text-gray-600 text-center mb-6 flex-grow">{service.description}</p>
          <div className="flex flex-row md:flex-col text-wrap gap-4 w-full mt-4 space-y-2">
            <button
              style={{ backgroundColor: APP_COLOR }}
              className="text-white px-4 py-3 md:py-4 rounded-lg hover:bg-green-600 transition flex-1 
              w-full min-h-[50px] md:min-h-[60px] 
              flex items-center justify-center text-center"
              onClick={() => handleButtonClick(service.buttonLabel)}
            >
              {service.buttonLabel}
            </button>
            <button
              className="text-white px-4 py-3 md:py-4 rounded-lg bg-black border-2 border-black 
              transition flex-1 w-full min-h-[50px] md:min-h-[60px] 
              flex items-center justify-center text-center"
              onClick={() => handleButtonClick2(service.buttonLabe2)}
            >
              {service.buttonLabe2}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceCards;
