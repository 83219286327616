import React from 'react'
import AboutUs from '../About/Aboutus'
import Content from '../Content/Content'
import Matrices from '../Metrics/Metrics'
import VideoPlayer from './Youtube player/Youtube_Player'
import Testimonials from '../Testimonials/Testimonials'


function Home() {
  return (
    <>
    <AboutUs/>
    <Testimonials/>
    <Matrices/>
    <Content/>
    <VideoPlayer/>
    
    </>
        
      
    
  )
}

export default Home
